"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Asset = exports.AssetMetadata = void 0;
const typebox_1 = require("@sinclair/typebox");
const audio_config_model_1 = require("./audio-config-model");
const caption_config_model_1 = require("./caption-config-model");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
const stream_model_1 = require("./stream-model");
const transfer_model_1 = require("./transfer-model");
const watermark_model_1 = require("./watermark-model");
exports.AssetMetadata = typebox_1.Type.Object({
    width: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Integer(), typebox_1.Type.Null()])),
    height: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Integer(), typebox_1.Type.Null()])),
    duration: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()])),
    status: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Literal('finalized')])),
});
exports.Asset = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    uri: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    fileType: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    fileSize: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()])),
    fileName: typebox_1.Type.String(),
    originalFileName: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    transferId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    audioConfigs: typebox_1.Type.Array(audio_config_model_1.AudioConfig),
    captionConfigs: typebox_1.Type.Array(caption_config_model_1.CaptionConfig),
    streams: typebox_1.Type.Array(stream_model_1.Stream),
    transfer: typebox_1.Type.Optional(typebox_1.Type.Union([transfer_model_1.Transfer, typebox_1.Type.Null()])),
    watermarks: typebox_1.Type.Array(watermark_model_1.Watermark),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
    metadata: exports.AssetMetadata,
});
